<template>
  <div class="board-card rounded border border-transparent bg-gray-100 px-4 py-6 transition-colors hover:border-primary hover:text-inherit md:px-6 md:py-8 lg:rounded-lg">
    <!-- username -->
    <div class="flex items-center justify-between">
      <NuxtLink :to="board.user.username" class="text-xs lg:text-sm">
        @{{ board.user.username }}
      </NuxtLink>
    </div>

    <!-- user avatar -->
    <div class="mt-3 flex w-full items-center gap-4">
      <img
        class="h-16 w-16 rounded-full"
        :src="avatarUrl"
        alt="avatar"
      >
      <div class="flex flex-col gap-1">
        <h4 class="text-sm font-semibold md:text-base lg:text-lg">
          <NuxtLink :to="`/${board.user.username}`">
            {{ `${board.user.first_name} ${board.user.last_name}` }}
          </NuxtLink>
        </h4>
        <p class="text-xs font-light">
          {{ board.user.about }}
        </p>
        <time class="text-xs font-light" :datetime="board.created_at">{{ $f.date(board.created_at, 'DD/MM/YYYY [at] HH:mm a') }}</time>
      </div>
    </div>

    <!-- board information -->
    <NuxtLink :to="boardPath" class="group mt-5 flex items-start justify-between gap-1 md:gap-3">
      <div class="overflow-x-hidden">
        <h3 class="mb-1 text-sm font-semibold capitalize tracking-wide group-hover:text-primary md:text-lg">
          {{ board.name }}
        </h3>
        <p class="mt-5 line-clamp-3 text-xs font-light md:text-sm">
          {{ board.description }}
        </p>
      </div>
      <div v-if="board.cover" class="h-20 w-20 shrink-0 md:h-28 md:w-28">
        <img :src="board.cover.thumb" alt="board cover image" class="h-full w-full rounded-md">
      </div>
    </NuxtLink>
    <hr class="mt-5">

    <!-- board actions -->
    <div class="mt-5 flex items-center justify-between text-xs text-gray-400 md:justify-start md:gap-10 md:text-sm">
      <BookmarkButton v-if="board.permissions.bookmark" :bookmarkable="board" type="boards" :show-count="false">
        <template #after>
          <span class="hover:text-primary" v-text="board.bookmarked ? 'Bookmarked' : 'Bookmark'" />
        </template>
      </BookmarkButton>

      <CopyButton :content="copyText" class="flex items-center gap-2">
        <DuplicateIcon class="h-5 w-5" />
        <span class="hover:text-primary">Copy Link</span>
      </CopyButton>

      <button type="button" class="flex cursor-default items-center gap-2">
        <ChatIcon class="h-5 w-5" />
        {{ board.comments_count }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import DuplicateIcon from '~/components/icons/DuplicateIcon'
import ChatIcon from '~/components/icons/ChatIcon'
import BookmarkButton from '~/components/bookmarks/BookmarkButton'
import CopyButton from '~/components/ui/CopyButton'
import { useAuthStore } from '~/stores/auth'
import { useApi } from '~/composables/useApi'

export default {
  components: { CopyButton, DuplicateIcon, ChatIcon, BookmarkButton },

  props: {
    board: { type: Object, required: true },
  },

  data: () => ({
    bookmarked: false,
    url: '',
    boardUrl: '',
    showMenu: false,
    loading: {
      bookmark: false,
    },
  }),

  computed: {
    ...mapState(useAuthStore, ['user']),

    avatarUrl () {
      if (! this.board.user.avatar) {
        return `${this.$config.public.publicUrl}/images/user/avatar/thumb/missing.jpg`
      }
      return this.board.user.avatar.thumb
    },

    boardPath () {
      return `${this.board.user.username}/${this.board.slug}`
    },

    copyText () {
      if (process.browser) {
        return `${window.location.origin}/${this.boardPath}`
      }
      return ''
    },
  },

  mounted () {
    this.bookmarked = this.board.bookmarked
    this.boardUrl = `${window.location}${this.board.user.username}/${this.board.slug}`
  },

  methods: {
    hideMenu () {
      this.showMenu = false
    },

    toggleBookmark () {
      if (! this.user) {
        return this.$router.push('/users/sign-in')
      }

      this.loading.bookmark = true

      if (this.bookmarked) {
        useApi(`/api/bookmarks?bookmarkable_id=${this.board.id}&bookmarkable_type=boards`, { method: 'delete' })
          .then(() => {
            this.bookmarked = false
            this.loading.bookmark = false
          })
      } else {
        useApi('/api/bookmarks', { method: 'post', body: { bookmarkable_id: this.board.id, bookmarkable_type: 'boards' } })
          .then(() => {
            this.bookmarked = true
            this.loading.bookmark = false
          })
      }
    },
  },

}
</script>
